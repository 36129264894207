import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import parse from 'html-react-parser';
import isString from 'lodash/isString';
import React, { useCallback, useRef } from 'react';
import { useComplaintForm } from 'src/app/context';
import styled from 'styled-components';
import { Header, HeaderNote } from '../../complaint-form-styles';
import { SelectedFiles } from '../../selected-files';
import { TFileUploadProps } from './types';

const StyledIconButton = styled(IconButton)`
  background-color: var(--color-primary);
  color: var(--color-white);
  height: 2.1875rem;
  width: 2.1875rem;

  :hover {
    background-color: var(--color-primary-light);
  }
`;

const StyledSpan = styled.span`
  margin-left: 0.9375rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 1.1px;
  text-align: center;
  color: var(--color-primary);

  @media screen and (max-width: 360px) {
    font-size: 0.85rem;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  :hover {
    cursor: pointer;
  }
`;

export function Uploads(props: TFileUploadProps): JSX.Element {
  const { params = {} } = props;

  const { supplementalText } = params;

  const { updateSelectedFiles } = useComplaintForm();

  const inputRef = useRef<HTMLInputElement>(null);

  const openFileSelection = useCallback(() => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  }, []);

  const handleFilesSelected = useCallback((e) => {
    updateSelectedFiles(e.target.files);
  }, [updateSelectedFiles]);

  return (
    <div data-cy="file-upload-module">
      <Header>Attachments (Optional)</Header>

      <HeaderNote>
        <p>Maximum number of files: 5; maximum file size: 150MB</p>
        {isString(supplementalText) && (
          <div data-cy="file-upload-additional-text">{parse(supplementalText)}</div>
        )}
      </HeaderNote>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <StyledLabel htmlFor="file-upload">
        <StyledIconButton onClick={openFileSelection} aria-label="file-upload">
          <AddIcon />
        </StyledIconButton>
        <StyledInput
          id="file-upload"
          type="file"
          name="file-upload"
          multiple
          onChange={handleFilesSelected}
          ref={inputRef}
        />
        <StyledSpan>UPLOAD PHOTOS, VIDEOS OR AUDIO</StyledSpan>
      </StyledLabel>

      <SelectedFiles />
    </div>
  );
}
