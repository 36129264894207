import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ComplaintsForm } from 'src/complaints/complaints-form';
import { ErrorPage } from 'src/complaints/error-page';
import { FormHeader } from 'src/complaints/form-header';
import { SuccessPage } from 'src/complaints/success-page';
import { getStats } from 'src/complaints/utils';
import { TStats } from 'src/types/types';
import { history } from './history';

export function Routes(): JSX.Element {
  const [stats, setStats] = useState<TStats>();

  useEffect(() => {
    (async () => getStats(setStats))();
  }, []);

  if (isNil(stats)) return <div />;

  return (
    <>
      <FormHeader
        rootPath={stats?.app?.rootPath}
        logo={stats?.cityInfo?.logo}
        jurisdictionName={stats?.cityInfo?.name}
      />
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <ComplaintsForm stats={stats} />
          </Route>

          <Route exact path="/success" component={SuccessPage} />
          <Route exact path="/error" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
}
