import * as Sentry from '@sentry/browser';
import axios from 'axios';
import get from 'lodash/get';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import appSettings from 'src/app-settings.json';
import { ComplaintsWrapper } from 'src/app-styles';
import { ComplaintFormProvider, useComplaintForm } from 'src/app/context';
import { history } from 'src/app/history';
import { TStats } from 'src/types/types';
import { ComplaintDescription } from './complaint-description';
import { ComplaintType } from './complaint-type';
import { IFormInputs } from './complaint-types';
import { Disclaimer } from './disclaimer';
import { PriorComplaint } from './modules/prior-complaints/prior-complaint';
import { OffendingProperty } from './offending-property';
import { OptionalInfo } from './optional-info';
import { ComplainantInfo } from './modules/complainant-info/complainant-info';
import { SubmissionModule } from './submission-module';
import { Uploads } from './modules/file-uploads/uploads';
import { convertYesNoToBoolean } from './utils';

interface IProps {
  stats: TStats;
}

function ComplaintsFormComponent(props: IProps): JSX.Element {
  const { stats } = props;

  const { control, errors, watch, register, unregister, handleSubmit } = useForm<IFormInputs>({
    criteriaMode: 'all',
    shouldFocusError: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [typesSelected, setTypesSelected] = useState<boolean>(false);
  const [showTypeError, setShowTypeError] = useState<boolean>(false);
  const complaintTypeRef = useRef<HTMLDivElement>(null);

  const {
    complaintTypes,
    selectedComplaintTypes,
    address,
    apn,
    selectedFiles,
  } = useComplaintForm();

  const scrollToTypes = useCallback(() => complaintTypeRef?.current?.scrollIntoView({}), []);

  useEffect(() => {
    const anyTypeSelected = Object.values(complaintTypes).some((type) => type);
    setTypesSelected(anyTypeSelected);
  }, [complaintTypes]);

  async function postS3Upload(formdata: FormData) {
    try {
      const response = await axios({
        method: 'post',
        url: `${appSettings.s3Domain}`,
        data: formdata,
      });
      // console.info('> Response status: ', response.status);
    } catch (error) {
      Sentry.captureException(error);
      Sentry.captureMessage('Error: Error uploading attachment');
      // console.error('Error uploading attachment:', error);
    }
  }

  const memoizedOnSubmit = useCallback(
    (data: IFormInputs): void => {
      async function axiosPostS3Token(
        // data: IFormInputs,
        complaint_types: string[],
        files: { name: string, mimetype: string }[],
      ) {
        let isSubmissionError = false;
        try {
          // Step 1 - POST Request to Retrieve Token
          // console.info('Step 1: Axios POST Request to Obtain AWS S3 Token');

          const response = await axios({
            method: 'post',
            url: `${appSettings.strComplaintsApiDomain}/${stats?.cityInfo?.place}/lodgeComplaint`,
            data: { ...convertYesNoToBoolean(data), complaint_types, files, address, apn },
          });
          // console.info('> Response status: ', response.status);
          const complaintId = response.data.compliant_id;
          const tokenResponse = response.data.upload_tokens;

          // Convert tokenResponse object to an Array
          const tokenResponseArray = Object.entries(tokenResponse);

          if (tokenResponseArray.length > 0) {
            try {
              // Step 2 (Optional) - Form-data POST Request Upload File(s) (If file(s) attached)
              // eslint-disable-next-line max-len
              // console.info(`Step 2: Axios POST Request to Upload ${tokenResponseArray.length} File(s) to AWS S3`);

              // Loop through array, and for each file, generate the form-data,
              // append the file at the end, and make the POST request.
              tokenResponseArray.forEach((e, index:number) => {
                // const formFields = tokenResponse[Object.keys(tokenResponse)[index]].fields;
                const formFields = tokenResponse[Object.keys(tokenResponse)[index]].fields;

                // Create form-data for the POST submission
                const formdata = new FormData();

                // Populate form-data with formFields from Step 1
                Object.keys(formFields).forEach((i) => {
                  formdata.append(i, formFields[i]);
                });

                // Append the file to be uploaded at the end
                formdata.append('file', selectedFiles[index], selectedFiles[index].name);

                postS3Upload(formdata);
              });
            } catch (error) {
              Sentry.captureException(error);
              Sentry.captureMessage('Error: Error uploading attachment');
              // console.error('Error uploading attachment:', error);
              isSubmissionError = true;
            }
          }
          if (!isSubmissionError) {
            history.push({ pathname: '/success', state: complaintId });
          } else {
            history.push({ pathname: '/error' });
          }
        } catch (error) {
          Sentry.captureException(error);
          Sentry.captureMessage('Error: Error submitting complaint');
          // console.error('Error submitting complaint:', error);
          isSubmissionError = true;
        }
      }

      setIsLoading(true);

      if (typesSelected) {
        // Restructuring "complaint_types" to match AWS S3 file upload POST request schema
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const complaint_types = selectedComplaintTypes;

        // Restructuring "selectedFiles" array to "files" to match AWS S3 POST request schema
        const files = selectedFiles.map((file) => ({
          name: file.name,
          mimetype: file.type,
        }));
        axiosPostS3Token(complaint_types, files);

        setTimeout(() => {
          setIsLoading(false);
        }, 1250);
      } else {
        scrollToTypes();
        setShowTypeError(true);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line max-len
    [typesSelected, stats?.cityInfo?.place, address, apn, selectedFiles, selectedComplaintTypes, scrollToTypes],
  );

  return (
    <ComplaintsWrapper>
      <form onSubmit={handleSubmit(memoizedOnSubmit)}>
        <Disclaimer />

        <ComplaintType
          refProp={complaintTypeRef}
          showTypeError={showTypeError}
          setShowTypeError={setShowTypeError}
        />

        <OffendingProperty control={control} errors={errors} stats={stats} />

        <PriorComplaint
          control={control}
          errors={errors}
          watch={watch}
          register={register}
          unregister={unregister}
          params={get(stats, 'form.standardSections.priorComplaints')}
        />

        <ComplaintDescription control={control} errors={errors} />

        <ComplainantInfo
          control={control}
          errors={errors}
          params={get(stats, 'form.standardSections.complainantInfo')}
        />

        <Uploads params={get(stats, 'form.standardSections.fileUpload')} />

        <OptionalInfo control={control} errors={errors} />

        <SubmissionModule isLoading={isLoading} />
      </form>
    </ComplaintsWrapper>
  );
}

export function ComplaintsForm(props: IProps): JSX.Element {
  const { stats } = props;

  return (
    <ComplaintFormProvider>
      <ComplaintsFormComponent stats={stats} />
    </ComplaintFormProvider>
  );
}
