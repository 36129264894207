import * as Sentry from '@sentry/browser';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import appSettings from 'src/app-settings.json';
import { domainlessPortalPlaceName, isDomainlessPortal } from 'src/app/history';
import { TStats } from 'src/types/types';
import { IFormInputs } from './complaint-types';
import { LOWER_NO, LOWER_YES } from './constants';

type SetStatsType = (args: TStats) => void;

export async function getStats(setStats: SetStatsType): Promise<void> {
  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_, ...domain] = window.location.hostname.split('.');

    const queryParams = new URLSearchParams();
    queryParams.append(
      'originUrl',
      isDomainlessPortal ? `${domainlessPortalPlaceName}.${domain.join('.')}` : window.location.hostname,
    );

    const { data } = await axios.get(
      `${appSettings.strComplaintsApiDomain}/stats?${queryParams.toString()}`,
    );

    setStats(data);
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage('Error: Error fetching /stats');
  }
}

// Unsupported of nested values
export function convertYesNoToBoolean(payload: IFormInputs): IFormInputs {
  const cloned = cloneDeep(payload);

  Object.keys(cloned).forEach((key) => {
    const keyValue = get(cloned, key);
    if (keyValue === LOWER_YES) set(cloned, key, true);
    if (keyValue === LOWER_NO) set(cloned, key, false);
  });

  return cloned;
}
