import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';

const StyledButton = styled(Button)`
  margin-top: 3.5714rem;
  width: 100%;
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  :hover {
    background-color: var(--color-light-blue);
  }

  @media screen and (min-width: 768px) {
    width: 8rem;
  }
`;

const SubmissionSubtext = styled.p`
  font-size: var(--font-size-sm);
  color: var(--color-dark-grey);
`;

const StyledProgress = styled(CircularProgress)`
  color: var(--color-white);
`;

interface ISubmissionModuleProps {
  isLoading: boolean,
}

export function SubmissionModule(props: ISubmissionModuleProps): JSX.Element {
  const { isLoading } = props;

  return (
    <>
      <StyledButton
        type="submit"
        variant="contained"
        disabled={isLoading}
      >
        {isLoading ? <StyledProgress size="1.5rem" /> : 'Submit'}
      </StyledButton>

      <SubmissionSubtext>
        The correct department will be notified of your concern and
        enforcement action will be taken as appropriate.
        Most property owners take corrective action upon receiving an initial notice of complaint.
      </SubmissionSubtext>
    </>
  );
}
