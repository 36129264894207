import React from 'react';
import styled from 'styled-components';

const DisclaimerAlert = styled.div`
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  color: var(--color-warning);
`;

const DisclaimerText = styled.div`
  text-align: center;
`;

export const Disclaimer = (): JSX.Element => (
  <div>
    <DisclaimerAlert>If this is an emergency, please contact 911.</DisclaimerAlert>
    <DisclaimerText>
      Otherwise, please fill out the form below to file a complaint about a
      short-term rental property.
    </DisclaimerText>
  </div>
);
