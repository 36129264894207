import React from 'react';
import styled from 'styled-components';
import { ComplaintsWrapper } from 'src/app-styles';
import { FormHeader } from './form-header';

const StyledHeading = styled.p`
  display: block;
  margin: 2rem auto 0;
  font-size: var(--font-size-lg);
  font-weight: 500;
  line-height: 1.5;
`;

const StyledMessage = styled.p`
  display: block;
  margin: 1rem auto 0;
  line-height: 1.5;
  letter-spacing: 0.02rem;
  font-size: var(--font-size-md);
`;

const Image = styled.img`
  display: block;
  margin: auto;
  margin-top: 5rem;
  width: 75%;
  max-width: 240px;

  @media screen and (min-width: 768px) {
    margin-top: 8.75rem;
  }
`;

export function ErrorPage(): JSX.Element {
  return (
    <ComplaintsWrapper>
      <Image
        src="/assets/general-error.svg"
        alt="Complaint form submission error"
      />

      <StyledHeading>
        Error Submitting Complaint
      </StyledHeading>
      <StyledMessage>
        Oops, there was an error submitting your complaint.
        The system adminstrator has been notified of this error.
      </StyledMessage>
    </ComplaintsWrapper>
  );
}
