import React from 'react';
import { Routes } from './app/routes';
import { GlobalStyles } from './app-styles';

function App(): JSX.Element {
  return (
    <>
      <GlobalStyles />
      <Routes />
    </>
  );
}

export default App;
