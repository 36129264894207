import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormMethods,
} from 'react-hook-form';

/* ---------------------------------------------------------------------
 *
 * Types and interfaces used in context.ts must be defined here to avoid
 * cyclic dependencies
 *
 * -------------------------------------------------------------------*/

export interface IComplaintTypes {
  complaintTypes: Record<string, boolean>,
  selectedComplaintTypes: [],
}
export interface IComplaintType {
  complaintType: string,
  selectedComplaintTypes: [],
}

// Renamed output to match AWS S3 API Post Request naming convention
export enum FormInputTypes {
  propertyOwnerName = 'owner_name',
  listingURL = 'listing_url',
  reporterFirstName = 'contact_first_name',
  reporterLastName = 'contact_last_name',
  reporterPhoneNumber = 'contact_phone',
  reporterEmailAddress = 'contact_email',
  complaintDescription = 'description',
  address = 'address',
  apn = 'apn',
  AptOrUnit = 'unit',
  hasPriorComplaint = 'has_prior_complaint',
  priorComplaintID = 'prior_complaint_id',
}

interface IOffendingProperty {
  [FormInputTypes.address]: string,
  [FormInputTypes.apn]: string,
  [FormInputTypes.AptOrUnit]: string,
}

interface IPriorComplaintsInfo {
  // Record as string, send as boolean
  [FormInputTypes.hasPriorComplaint]: string | boolean,
  [FormInputTypes.priorComplaintID]?: string,
}

interface IDescription {
  [FormInputTypes.complaintDescription]: string,
}

interface IReporterInfo {
  [FormInputTypes.reporterFirstName]: string,
  [FormInputTypes.reporterLastName]: string,
  [FormInputTypes.reporterPhoneNumber]: string,
  [FormInputTypes.reporterEmailAddress]: string,
}
interface IOptionalInfo {
  [FormInputTypes.propertyOwnerName]: string,
  [FormInputTypes.listingURL]: string,
}

export interface IFormInputs extends
  IComplaintType,
  IOffendingProperty,
  IPriorComplaintsInfo,
  IDescription,
  IReporterInfo,
  IOptionalInfo {
  // This is to allow us to use computed values to access an object
  // [key: string]: string;
}

export interface IFormModuleProps {
  control: Control,
  errors: DeepMap<IFormInputs, FieldError>,
  watch?: UseFormMethods<FieldValues>['watch'],
  register?: UseFormMethods<FieldValues>['register'],
  unregister?: UseFormMethods<FieldValues>['unregister'],
}
