interface ILength {
  value: number,
  message: string,
}

export const formRules = {
  REQUIRED: { value: true, message: 'Field is required' },
  MAX_LENGTH: (length: number): ILength => ({
    value: length, message: `Field has a max length of ${length} characters`,
  }),
  MIN_LENGTH: (length: number): ILength => ({
    value: length, message: `Field has a minimum length of ${length} characters`,
  }),
};

export const UPPER_YES = 'Yes';
export const LOWER_YES = 'yes';

export const UPPER_NO = 'No';
export const LOWER_NO = 'no';
