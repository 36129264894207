import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Header } from './complaint-form-styles';
import { FormInputTypes, IFormModuleProps } from './complaint-types';
import { formRules } from './constants';

const StyledTextLabel = styled.p`
  font-size: var(--font-size-sm);
  line-height: 1.25;
  letter-spacing: 0.033rem;
  color: var(--color-dark-grey);
  text-align: right;
`;

const formModuleFields = [
  {
    name: FormInputTypes.complaintDescription,
    placeholder: 'Description of violations',
    rules: { required: formRules.REQUIRED, maxLength: formRules.MAX_LENGTH(2000) },
  },
];

export function ComplaintDescription(props: IFormModuleProps): JSX.Element {
  const { control, errors } = props;
  const CHARACTER_LIMIT = formModuleFields[0].rules.maxLength.value;

  // Function to watch the complaintDescription field, used to count the number of characters
  function ComplaintDescriptionCounter() {
    const complaintDescription = useWatch({
      control,
      name: 'description',
      defaultValue: '',
    });

    return (
      <StyledTextLabel>
        (
        {complaintDescription.length}
        /
        {CHARACTER_LIMIT}
        )
      </StyledTextLabel>
    );
  }

  return (
    <>
      <Header>
        Complaint Description
      </Header>

      {formModuleFields.map(({ name, placeholder, rules }) => (
        <Controller
          key={name}
          control={control}
          as={<TextField />}
          variant="outlined"
          defaultValue=""
          multiline
          rows={8}
          name={name}
          placeholder={placeholder}
          label={placeholder}
          rules={rules}
          error={Boolean(errors?.[name]?.message)}
          helperText={errors?.[name]?.message}
          required={Boolean(rules?.required)}
          fullWidth
          inputProps={
              { maxLength: CHARACTER_LIMIT }
            }
        />
      ))}
      <ComplaintDescriptionCounter />
    </>
  );
}
