import React, { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import AppsIcon from '@material-ui/icons/Apps';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { useComplaintForm } from 'src/app/context';
import { Header, HeaderErrorMessage } from './complaint-form-styles';

const StyledGrid = styled.div`
  display: grid;
  margin-left: 4rem;
  margin-right: 4rem;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface IStyleProps {
  selected: boolean,
}

const selectedColor = 'var(--color-dark-blue)';
const unselectedColor = 'var(--color-dark-grey)';
const selectedBGColor = 'var(--color-lighter-blue)';
const borderColor = 'var(--color-light-grey)';

const StyledButtonContainer = styled.div`
  display: grid;
  cursor: pointer;
  width: 8rem;
  height: 8rem;
  border-radius: var(--border-radius-sm);
  color: ${(props: IStyleProps) => (props.selected ? selectedColor : unselectedColor)};

  :hover {
      button {
      border: solid 0.5px ${selectedBGColor};
      background-color: ${selectedBGColor};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  display: block;
  margin: auto;

  ${(props: IStyleProps) => (
    props.selected ? {
      border: `solid 0.5px ${selectedBGColor}`,
      color: selectedColor,
      backgroundColor: selectedBGColor,
    } : {
      border: `solid 0.5px ${borderColor}`,
      color: unselectedColor,
      backgroundColor: 'transparent',
    }
  )}
`;

const StyledButtonLabel = styled.p`
  margin: 8px 0;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
`;

export enum ComplaintTypes {
  NOISE = 'noise',
  TRASH = 'trash',
  PARKING = 'parking',
  ILLEGAL_RENTAL = 'illegal_rental',
  NO_OF_GUESTS = 'number_guests',
  OTHER = 'other',
}

// Use 'type' as we can't use the [key in XYZ] computation for 'interfaces'
type ButtonsList = {
  [key in ComplaintTypes]: {
    icon: SvgIconComponent,
    label: string,
  };
};

const buttonsList: ButtonsList = {
  [ComplaintTypes.NOISE]: { icon: VolumeUpIcon, label: 'Noise' },
  [ComplaintTypes.TRASH]: { icon: DeleteIcon, label: 'Trash' },
  [ComplaintTypes.PARKING]: { icon: LocalParkingIcon, label: 'Parking' },
  [ComplaintTypes.ILLEGAL_RENTAL]: { icon: HomeIcon, label: 'Illegal Rental' },
  [ComplaintTypes.NO_OF_GUESTS]: { icon: GroupIcon, label: 'Number of Guests' },
  [ComplaintTypes.OTHER]: { icon: AppsIcon, label: 'Other' },
};

interface IComplaintTypeProps {
  refProp: React.RefObject<HTMLDivElement> | null,
  showTypeError: boolean,
  setShowTypeError: Dispatch<SetStateAction<boolean>>,
}

export function ComplaintType(props: IComplaintTypeProps): JSX.Element {
  const {
    refProp,
    showTypeError,
    setShowTypeError,
  } = props;

  const { complaintTypes, updateComplaintTypes } = useComplaintForm();

  const handleTypeSelection = useCallback(
    (type: string): void => {
      if (!complaintTypes[type]) setShowTypeError(false);
      updateComplaintTypes(type);
    },
    [complaintTypes, setShowTypeError, updateComplaintTypes],
  );

  return (
    <>
      <Header ref={refProp} error={showTypeError}>
        What type of complaint(s) are you reporting? *
      </Header>

      {showTypeError && (
        <HeaderErrorMessage>
          Type of complaint is required.
        </HeaderErrorMessage>
      )}

      <StyledGrid>
        {Object.values(ComplaintTypes).map((type) => {
          const ButtonIcon = buttonsList[type].icon;
          const buttonLabel = buttonsList[type].label;

          return (
            <StyledButtonContainer
              key={type}
              selected={complaintTypes[type]}
              onClick={() => {
                handleTypeSelection(type);
              }}
            >
              <StyledIconButton
                selected={complaintTypes[type]}
                aria-label={buttonLabel}
              >
                <ButtonIcon />
              </StyledIconButton>
              <StyledButtonLabel>{buttonLabel}</StyledButtonLabel>
            </StyledButtonContainer>
          );
        })}
      </StyledGrid>
    </>
  );
}
