import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TStats } from 'src/types/types';
import styled from 'styled-components';
import { Header, HeaderNote } from './complaint-form-styles';
import { FormInputTypes, IFormModuleProps } from './complaint-types';
import AutocompleteAddress from './components/autocomplete-address';
import { formRules } from './constants';

const AddressInputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: auto 10rem;
    column-gap: 1.7142rem;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: ${(props: Record<string, string>) => props.width};
  }
`;

const StyledAutocompleteAddress = styled(AutocompleteAddress)`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: ${(props: Record<string, string>) => props.width};
  }
`;

interface IOffendingProperty extends IFormModuleProps {
  stats: TStats;
}

export function OffendingProperty(props: IOffendingProperty): JSX.Element {
  const { stats, control, errors } = props;

  return (
    <div data-cy="offending-property-module">
      <Header>Where is the property you are reporting?</Header>
      <HeaderNote>
        Type 3 or more characters to search for an address.
      </HeaderNote>

      <AddressInputsGrid>
        <Controller
          control={control}
          as={<StyledAutocompleteAddress width="100%" />}
          variant="outlined"
          defaultValue=""
          name={FormInputTypes.address}
          placeholder="Address"
          label="Address"
          rules={{ maxLength: formRules.MAX_LENGTH(500) }}
          error={Boolean(errors?.[FormInputTypes.address]?.message)}
          helperText={errors?.[FormInputTypes.address]?.message}
          stats={stats}
          data-cy="offending-property-address-input-field"
        />

        <Controller
          control={control}
          as={<StyledTextField width="100%" />}
          variant="outlined"
          defaultValue=""
          name={FormInputTypes.AptOrUnit}
          placeholder="Apt or Unit #"
          label="Apt or Unit #"
          rules={{ maxLength: formRules.MAX_LENGTH(20) }}
          error={Boolean(errors?.[FormInputTypes.AptOrUnit]?.message)}
          helperText={errors?.[FormInputTypes.AptOrUnit]?.message}
          data-cy="offending-property-unit-number-input-field"
        />
      </AddressInputsGrid>
    </div>
  );
}
