import styled from 'styled-components';

interface IHeaderProps {
  error?: boolean,
}

export const Header = styled.div`
  font-weight: 500;
  line-height: 1;
  margin-top: 3.571rem;
  margin-bottom: 1.1428rem;
  color: ${(props: IHeaderProps) => (props.error ? 'var(--color-warning)' : 'var(--color-dark-grey)')};
`;

export const HeaderNote = styled.div`
  font-family: Roboto;
  font-size: var(--font-size-md);
  font-weight: 400;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 1.1428rem;
`;

export const HeaderErrorMessage = styled.div`
  font-size: var(--font-size-md);
  line-height: 1.25;
  letter-spacing: 0.033rem;
  color: var(--color-warning);
  margin-bottom: 8px;
`;
