import React, { useCallback } from 'react';
import styled from 'styled-components';
import { history } from 'src/app/history';

const StyledHeaderContainer = styled.div`
  margin-top: 0;
  margin-bottom: 20px;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-lighter-grey);
  padding-bottom: 10px;
`;

const Image = styled.img`
  height: 30px;
  width: auto;
`;

const StyledNameLabel = styled.span`
  margin-left: 15px;
  font-weight: 500;
`;

const StyledPageTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-lg);
  font-weight: 500;
`;

interface IProps {
  rootPath: string;
  logo: string;
  jurisdictionName: string;
}

export function FormHeader(props: IProps): JSX.Element {
  const {
    rootPath,
    logo,
    jurisdictionName,
  } = props;

  const goToRootPage = useCallback(() => {
    if (rootPath && history.location.pathname !== rootPath) {
      history.push(rootPath);
    }
  }, [rootPath]);

  return (
    <>
      <StyledHeaderContainer>
        <StyledLogoContainer>
          {jurisdictionName && (
            <>
              <Image src={logo} alt="City Logo" onClick={goToRootPage} />
              <StyledNameLabel>{jurisdictionName}</StyledNameLabel>
            </>
          )}
        </StyledLogoContainer>

        <StyledPageTitle>
          Short-Term Rental Complaint
        </StyledPageTitle>
      </StyledHeaderContainer>
    </>
  );
}
