import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Sentry from '@sentry/browser';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import appSettings from 'src/app-settings.json';
import { useComplaintForm } from 'src/app/context';
import { TStats } from 'src/types/types';

interface AddressOptionType {
  inputValue?: string;
  address: string;
  apn: string;
  value?: Record<string, string>;
}

const filter = createFilterOptions<AddressOptionType>({
  matchFrom: 'any',
  limit: 5,
});

interface IAutocompleteAddressProps {
  stats: TStats;
}

export default function AutocompleteAddress(props: IAutocompleteAddressProps): JSX.Element {
  const { stats } = props;

  const [value, setValue] = useState<AddressOptionType | null>(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { updateAddress } = useComplaintForm();

  if (value) updateAddress(value.address, '' || value.apn);

  const getAddressSuggestions = useCallback(async (input: string) => {
    try {
      setLoading(true);
      const encodedInput = encodeURIComponent(input);
      const response = await axios.get(
        `${appSettings.strComplaintsApiDomain}${stats?.cityInfo?.addressLookup}${encodedInput}`,
      );

      setData(Object.values(response.data)); // Convert object to array
      setLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      Sentry.captureMessage('Error: Error fetching /lookupAddress');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [stats?.cityInfo?.addressLookup]);

  return (
    <Autocomplete
      id="autocomplete-address"
      aria-controls="autocomplete-address"
      aria-autocomplete="list"
      options={data}
      value={value}
      onChange={(event, newValue) => {
        // Custom address text entered by user and pressing Enter
        if (typeof newValue === 'string') {
          setValue({
            address: newValue,
            apn: '-',
          });
        } else if (newValue && newValue.inputValue) {
          // Custom address entered by user and selecting the "Custom Address" option
          setValue({
            address: newValue.inputValue,
            apn: '-',
          });
        } else {
          // Regular option from list
          setValue(newValue);
        }
      }}
      loading={loading}
      onInputChange={(event, inputValue) => {
        if (inputValue.length < 3) {
          setData([]);
        }
        if (inputValue.length >= 3) {
          getAddressSuggestions(inputValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a custom address
        if (params.inputValue !== '' && params.inputValue.length >= 3) {
          filtered.push({
            inputValue: params.inputValue,
            address: `Custom Address: "${params.inputValue}"`,
            apn: '',
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected from the input
        if (typeof option === 'string') {
          return option;
        }
        // Custom address entered by user
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option from list
        return option.address;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.address, inputValue);
        const parts = parse(option.address, matches);

        // Highlights search text in results for increased usability
        return (
          <div>
            {parts.map((part, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Address"
          variant="outlined"
          value={value}
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      fullWidth
      freeSolo
    />
  );
}
