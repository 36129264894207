import React from 'react';
import styled from 'styled-components';
import { ComplaintsWrapper } from 'src/app-styles';
import { useLocation } from 'react-router-dom';

const StyledConfirmationNumber = styled.p`
  display: block;
  margin: 2rem auto 0;
  font-size: var(--font-size-lg);
  font-weight: 500;
  line-height: 1.5;
`;

const StyledSent = styled.p`
  display: block;
  margin: 1rem auto 0;
  line-height: 1.5;
  letter-spacing: 0.02rem;
  font-size: var(--font-size-md);
`;

const Image = styled.img`
  display: block;
  margin: auto;
  margin-top: 5rem;
  width: 75%;
  max-width: 240px;

  @media screen and (min-width: 768px) {
    margin-top: 8.75rem;
  }
`;

export function SuccessPage(): JSX.Element {
  const location = useLocation();

  return (
    <ComplaintsWrapper>
      <Image
        src="/assets/complaint-success.svg"
        alt="Complaint form submission success"
      />

      <StyledConfirmationNumber>
        Complaint ID:
        {' '}
        { location.state }
      </StyledConfirmationNumber>
      <StyledSent>Thank you! Your complaint has been sent.</StyledSent>
    </ComplaintsWrapper>
  );
}
