import styled, { createGlobalStyle } from 'styled-components';

/**
 * This is the wrapper that goes around the entire complaints form
 */
export const ComplaintsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;

  @media screen and (min-width: 768px) {
    margin-left: 10rem;
    margin-right: 10rem;
  }
`;

/**
 * Global CSS Styles.
 * Put here all styles that are not specific to a single component
 */
export const GlobalStyles = createGlobalStyle`
  :root {
    --color-dark-grey: rgba(0, 0, 0, 0.6);
    --color-grey: rgba(0, 0, 0, 0.5);
    --color-light-grey: rgba(0, 0, 0, 0.24);
    --color-lighter-grey: rgba(0, 0, 0, 0.1);
    --color-dark-blue: #1e88e5;
    --color-light-blue: #509ee3;
    --color-lighter-blue: #e3f2fd;
    --color-dark-red: #e53935;
    --color-light-red: #fec5bb;
    --color-lighter-red: #f8edeb;
    --color-white: #ffffff;
    --font-size-lg: 16px; @media (min-width: 768px) { --font-size-lg: 20px; };
    --font-size-md: 14px;
    --font-size-sm: 12px;
    
    --border-radius-sm: 4px;

    --color-default: var(--color-dark-grey);
    --color-primary: var(--color-dark-blue);
    --color-primary-light: var(--color-light-blue);
    --color-warning: var(--color-dark-red);
    
    /* Styles for custom TextField colors */
    & label.Mui-focused {
      color: var(--color-primary);
    }

    & .MuiOutlinedInput-root {
      & fieldset {
        transition: border-color 0.3s ease-in-out;
      }
      &.Mui-focused fieldset {
        border-color: var(--color-primary);
      }
      &:hover fieldset {
        border-color: var(--color-primary);
      }
    }
  }
 
  html, body, #root {
    font-family: 'Roboto' 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    overflow-x: hidden;
    color: var(--color-default);
    scroll-behavior: smooth;
  }

  #root {
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 768px) {
      max-width: 70rem;
    }
  }
`;
