import { TextField } from '@material-ui/core';
import parse from 'html-react-parser';
import isString from 'lodash/isString';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Header, HeaderNote } from '../../complaint-form-styles';
import { FormInputTypes } from '../../complaint-types';
import { formRules } from '../../constants';
import { TComplainantInfoProps } from './types';

const StyledGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 1rem 0;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 2fr);
    grid-gap: 1rem 1.5rem;
  }
`;

function createFormModuleFields(fieldsRequired: boolean) {
  const requiredValidation = fieldsRequired ? { required: formRules.REQUIRED } : {};

  return [
    {
      name: FormInputTypes.reporterFirstName,
      placeholder: 'First name',
      rules: { ...requiredValidation, maxLength: formRules.MAX_LENGTH(50) },
    },
    {
      name: FormInputTypes.reporterLastName,
      placeholder: 'Last name',
      rules: { ...requiredValidation, maxLength: formRules.MAX_LENGTH(50) },
    },
    {
      name: FormInputTypes.reporterPhoneNumber,
      placeholder: 'Phone number',
      rules: { ...requiredValidation, minLength: formRules.MIN_LENGTH(14) },
      customComponent: (
        <NumberFormat customInput={TextField} format="(###) ###-####" mask="" />
      ),
    },
    {
      type: 'email',
      name: FormInputTypes.reporterEmailAddress,
      placeholder: 'Email',
      rules: { ...requiredValidation, maxLength: formRules.MAX_LENGTH(255) },
    },
  ];
}

export function ComplainantInfo(props: TComplainantInfoProps): JSX.Element {
  const { control, errors, params = {} } = props;

  const {
    supplementalText,
    requireContactInformation = false,
  } = params;

  const formModuleFields = useMemo(
    () => createFormModuleFields(requireContactInformation),
    [requireContactInformation],
  );

  return (
    <>
      <Header>
        Your Contact Information
        {requireContactInformation ? '' : ' (Optional)'}
      </Header>

      <HeaderNote>
        {isString(supplementalText) && (
          <div data-cy="complainant-details-additional-text">{parse(supplementalText)}</div>
        )}
        <p>
          Providing your contact information will aid the officer
          conducting the investigation for this complaint.
        </p>
      </HeaderNote>

      <StyledGrid>
        {formModuleFields.map(({
          type, name, placeholder, rules, customComponent,
        }) => (
          <Controller
            key={name}
            control={control}
            as={customComponent || TextField}
            variant="outlined"
            defaultValue=""
            name={name}
            type={type}
            placeholder={placeholder}
            label={placeholder}
            rules={rules}
            error={Boolean(errors?.[name]?.message)}
            helperText={errors?.[name]?.message}
          />
        ))}
      </StyledGrid>
    </>
  );
}
