import { createBrowserHistory } from 'history'; // eslint-disable-line import/no-extraneous-dependencies

const DOMAINLESS_PORTAL_SUB_DOMAINS = ['complaints-str'];

export const [, domainlessPortalPlaceName] = window.location.pathname.split('/');

export const isDomainlessPortal = DOMAINLESS_PORTAL_SUB_DOMAINS.includes(window.location.hostname.split('.')[0]);

export const history = createBrowserHistory({
  basename: isDomainlessPortal ? `/${domainlessPortalPlaceName}` : undefined,
});
