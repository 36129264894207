import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import appSettings from './app-settings.json';

interface IErrorFallbackProps {
  resetErrorBoundary: () => void,
}

function ErrorFallback({ resetErrorBoundary }: IErrorFallbackProps): JSX.Element {
  useEffect(() => { resetErrorBoundary(); }, [resetErrorBoundary]);

  return <Redirect to="/error" />;
}

Sentry.init({
  dsn: 'https://dc456fed547d43278bc239c12be4ae6d@o323940.ingest.sentry.io/6097764',
  integrations: [new Integrations.BrowserTracing()],
  environment: appSettings.environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  // https://reactjs.org/docs/strict-mode.html
  <React.StrictMode>
    <CssBaseline />
    <Router>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
