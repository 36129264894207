import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Header } from './complaint-form-styles';
import { FormInputTypes, IFormModuleProps } from './complaint-types';
import { formRules } from './constants';

const GRID_ROW_GAP = '1rem';

const StyledGrid = styled.div`
  display: grid;
  grid-gap: ${GRID_ROW_GAP} 0;
`;

const StyledTextField = styled(TextField)`
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (min-width: 1280px) {
    width: ${(props: Record<string, string>) => props.width};
  }
`;

const formModuleFields = [
  {
    name: FormInputTypes.propertyOwnerName,
    placeholder: 'Property owner\'s name',
    rules: { maxLength: formRules.MAX_LENGTH(100) },
    width: `calc(50% - ${GRID_ROW_GAP})`,
  },
  {
    name: FormInputTypes.listingURL,
    placeholder: 'Short-term rental listing URL',
    rules: { maxLength: formRules.MAX_LENGTH(2000) },
    width: '100%',
  },
];

export function OptionalInfo(props: IFormModuleProps): JSX.Element {
  const { control, errors } = props;

  return (
    <>
      <Header>Optional Information</Header>

      <StyledGrid>
        {formModuleFields.map(({
          name, placeholder, rules, width,
        }) => (
          <Controller
            key={name}
            control={control}
            as={<StyledTextField width={width} />}
            variant="outlined"
            defaultValue=""
            name={name}
            placeholder={placeholder}
            label={placeholder}
            rules={rules}
            error={Boolean(errors?.[name]?.message)}
            helperText={errors?.[name]?.message}
          />
        ))}
      </StyledGrid>
    </>
  );
}
