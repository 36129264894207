import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Header } from '../../complaint-form-styles';
import { FormInputTypes } from '../../complaint-types';
import {
  /**
   * Labels and values are reversed as the payload field is called
   * "has_prior_complaints"
   */
  UPPER_YES as HAS_NO_PRIOR_LABEL, // This is the first complaint
  LOWER_NO as HAS_NO_PRIOR_VALUE, // has_prior_complaints = no
  UPPER_NO as HAS_PRIOR_LABEL, // This is NOT the first complaint
  LOWER_YES as HAS_PRIOR_VALUE,
  formRules,
} from '../../constants';
import { TPriorComplaintProps } from './types';

const GRID_ROW_GAP = '1rem';

const StyledTextField = styled(TextField)`
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (min-width: 1280px) {
    width: ${(props: Record<string, string>) => props.width};
  }
`;

export function PriorComplaint(props: TPriorComplaintProps): JSX.Element {
  const { params, control, errors, watch, register, unregister } = props;

  const isPriorComplaintSectionDisabled = useMemo(() => (
    !params?.enabled
  ), [params?.enabled]);

  // eslint-disable-next-line consistent-return
  const radioValue = useMemo(() => {
    if (watch) return watch(FormInputTypes.hasPriorComplaint);
  }, [watch]);

  // This is NOT the user's first complaint
  const displayRefNumberInput = useMemo(() => radioValue === HAS_PRIOR_VALUE, [radioValue]);

  useEffect(() => {
    // This is the user's first complaint
    if (unregister && radioValue === HAS_NO_PRIOR_VALUE) {
      unregister(FormInputTypes.priorComplaintID);
    }

    // This is NOT the user's first complaint
    if (register && radioValue === HAS_PRIOR_VALUE) register(FormInputTypes.priorComplaintID);
  }, [radioValue, register, unregister]);

  if (isPriorComplaintSectionDisabled) return <div />;

  return (
    <div data-cy="prior-complaints-module">
      <FormControl component="fieldset">
        <Header>Is this your first complaint about this property?</Header>
        <Controller
          name={FormInputTypes.hasPriorComplaint}
          as={(
            <RadioGroup aria-label="first-complaint" name="first-complaint">
              <FormControlLabel
                control={<Radio required />}
                label={HAS_NO_PRIOR_LABEL}
                value={HAS_NO_PRIOR_VALUE}
              />
              <FormControlLabel
                control={<Radio required />}
                label={HAS_PRIOR_LABEL}
                value={HAS_PRIOR_VALUE}
              />
            </RadioGroup>
          )}
          control={control}
          error={Boolean(errors?.[FormInputTypes.hasPriorComplaint]?.message)}
          helperText={errors?.[FormInputTypes.hasPriorComplaint]?.message}
        />
      </FormControl>

      {displayRefNumberInput && (
        <>
          <Header>Reference number of previous complaint</Header>

          <Controller
            key={FormInputTypes.priorComplaintID}
            control={control}
            as={<StyledTextField width={`calc(50% - ${GRID_ROW_GAP})`} />}
            variant="outlined"
            defaultValue=""
            name={FormInputTypes.priorComplaintID}
            placeholder="Reference number"
            label="Reference number"
            rules={{ required: formRules.REQUIRED, maxLength: formRules.MAX_LENGTH(100) }}
            error={Boolean(errors?.[FormInputTypes.priorComplaintID]?.message)}
            helperText={errors?.[FormInputTypes.priorComplaintID]?.message}
          />
        </>
      )}
    </div>
  );
}
