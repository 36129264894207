import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from 'react';
import { IComplaintTypes } from 'src/complaints/complaint-types';
import uniqBy from 'lodash/uniqBy';
import { bool } from 'aws-sdk/clients/signer';

interface IComplaintFormContext {
  complaintTypes: IComplaintTypes['complaintTypes'];
  selectedComplaintTypes: string[];
  updateComplaintTypes: (type: string) => void;

  address: string;
  apn: string;
  updateAddress: (address: string, apn: string) => void;

  selectedFiles: File[];
  updateSelectedFiles: (selectedFiles: FileList) => void;
  removeSelectedFile: (index: number) => void;
  invalidSelectedFiles: File[];
  isFileLimitReached: bool;
  fileLimitReachedError: string;
}

const ComplaintFormContext = createContext<IComplaintFormContext | undefined>(
  undefined,
);

interface IComplaintTypeProvider {
  children: ReactNode,
}

export const ComplaintFormProvider = (
  { children }: IComplaintTypeProvider,
): JSX.Element => {
  const [complaintTypes, setComplaintTypes] = useState<Record<string, boolean>>({
    noise: false,
    trash: false,
    parking: false,
    illegal_rental: false,
    number_guests: false,
    other: false,
  });

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [invalidSelectedFiles, setInvalidSelectedFiles] = useState<File[]>([]);
  const [isFileLimitReached, setIsFileLimitReached] = useState(false);
  const [fileLimitReachedError, setFileLimitReachedError] = useState('');
  const [address, setAddress] = useState('');
  const [apn, setAPN] = useState('');

  const fileLimit = 5;
  const fileSizeLimit = 157_286_400;

  // Complaint Types
  const updateComplaintTypes = useCallback((type: string): void => {
    setComplaintTypes({
      ...complaintTypes,
      [type]: !complaintTypes[type],
    });
  }, [complaintTypes, setComplaintTypes]);

  // Address
  const updateAddress = useCallback((newAddress: string, newAPN: string): void => {
    setAddress(newAddress);
    setAPN(newAPN);
  }, [setAPN, setAddress]);

  // Selected Files
  const handleUpdateSelectedFiles = useCallback((files: FileList): void => {
    // store in Array<File> format. FileList type not iterable

    // Filtering on files less than 150MB in bytes
    const newlySelectedFiles = Array.from(files).filter((file) => file.size < fileSizeLimit);
    setInvalidSelectedFiles(Array.from(files).filter((file) => file.size >= fileSizeLimit));
    const uniqFiles = uniqBy([...selectedFiles, ...newlySelectedFiles], 'name');

    // Limit the number of uploaded files to 5.
    // If this is exceeded, only the first 5 files are accepted.
    if (uniqFiles.length > fileLimit) {
      setIsFileLimitReached(true);
      setFileLimitReachedError(`Maximum number of attachments (${fileLimit}) reached.`);
      const limitedUniqFiles = uniqFiles.slice(0, fileLimit);
      setSelectedFiles(limitedUniqFiles);
    } else {
      setSelectedFiles(uniqFiles);
    }
  }, [selectedFiles, setSelectedFiles]);

  const handleRemoveSelectedFile = useCallback((index: number) => {
    const remainingFiles = [...selectedFiles.slice(0, index), ...selectedFiles.slice(index + 1)];
    setSelectedFiles(remainingFiles);
    if (remainingFiles.length < fileLimit) {
      setIsFileLimitReached(false);
      setFileLimitReachedError('');
    }
  }, [selectedFiles, setSelectedFiles]);

  // Store selected Complaint Types into array
  const selectedComplaintTypes = Object.keys(complaintTypes).filter((key) => complaintTypes[key]);

  const value = {
    address,
    apn,
    updateAddress,

    complaintTypes,
    selectedComplaintTypes,
    updateComplaintTypes,

    selectedFiles,
    updateSelectedFiles: handleUpdateSelectedFiles,
    removeSelectedFile: handleRemoveSelectedFile,
    invalidSelectedFiles,
    isFileLimitReached,
    fileLimitReachedError,
  };

  return (
    <ComplaintFormContext.Provider value={value}>
      {children}
    </ComplaintFormContext.Provider>
  );
};

export function useComplaintForm(): IComplaintFormContext {
  const context = useContext(ComplaintFormContext);

  if (context === undefined) {
    throw new Error('useComplaintForm must be used within a ComplaintFormProvider');
  }

  return context;
}
