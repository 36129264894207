import React from 'react';
import styled from 'styled-components';
import { useComplaintForm } from 'src/app/context';
import Button from '@material-ui/core/Button';

const SelectedFilesWrapper = styled.div`
  margin-top: 20px;
`;

const StyledFileListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  border: 1px dashed var(--color-light-grey);
  border-radius: var(--border-radius-sm);
`;

const StyledInvalidFileListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  border: 1px dashed var(--color-light-red);
  background-color: var(--color-lighter-red);
  border-radius: var(--border-radius-sm);
  padding: 6px 0;
`;

const FileName = styled.div`
  line-height: 1.5;
  margin-left: 10px;
  color: var(--color-grey);
  text-decoration: underline;
`;

const InvalidFileName = styled.div`
  line-height: 1.5;
  margin-left: 10px;
  color: var(--color-grey);
  text-decoration: underline;
`;

const InvalidFileNote = styled.span`
  line-height: 1.5;
  margin-right: 10px;
  color: var(--color-grey);
  font-size: 14px;
`;

const StyledFileLimitReached = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  align-items: center;
  border: 1px dashed var(--color-light-red);
  background-color: var(--color-lighter-red);
  border-radius: var(--border-radius-sm);
  padding: 6px 0;
`;

const StyledButton = styled(Button)`
  color: var(--color-grey);
`;

interface IFileListProps {
  name: File['name'];
  index: number;
  removeSelectedFile: (index: number) => void;
}

interface IInvalidFileListProps {
  name: File['name'];
}

function FileListItem({ name, index, removeSelectedFile }: IFileListProps) {
  return (
    <StyledFileListItem>
      <FileName>{name}</FileName>
      <StyledButton type="button" onClick={() => { removeSelectedFile(index); }}>
        REMOVE
      </StyledButton>
    </StyledFileListItem>
  );
}

function InvalidFileListItem({ name }: IInvalidFileListProps) {
  return (
    <StyledInvalidFileListItem>
      <InvalidFileName>{name}</InvalidFileName>
      <InvalidFileNote>Invalid upload - Exceeds maximum file size</InvalidFileNote>
    </StyledInvalidFileListItem>
  );
}
export function SelectedFiles(): JSX.Element {
  const {
    selectedFiles,
    removeSelectedFile,
    invalidSelectedFiles,
    isFileLimitReached,
    fileLimitReachedError,
  } = useComplaintForm();

  return (
    <SelectedFilesWrapper>
      {selectedFiles.map((file: File, i) => (
        <FileListItem
          key={file.name}
          name={file.name}
          index={i}
          removeSelectedFile={removeSelectedFile}
        />
      ))}

      {invalidSelectedFiles.map((file: File) => (
        <InvalidFileListItem
          key={file.name}
          name={file.name}
        />
      ))}

      {isFileLimitReached
          && <StyledFileLimitReached>{ fileLimitReachedError }</StyledFileLimitReached>}

    </SelectedFilesWrapper>
  );
}
